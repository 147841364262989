<template>
  <div>
    <h2 class="h4">Add Discount Code</h2>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Code</label>
            <input type="text" placeholder="Code" v-model="code.code" class="form-control" required>
          </div>
          <div class="form-group">
            <label>Value in %, <small>eg, 10 is 10% off</small></label>
            <input type="text" placeholder="Value" v-model="code.value" class="form-control" required>
            <span class="text-muted"><small>Maximum value saved is 99</small></span>
          </div>
          <div class="form-group">
            <label>How many times can it be used?</label>
            <input type="text" placeholder="Usage limit" v-model="code.left" class="form-control" required>
          </div>
          <div class="form-group">
            <label>Expiry Date</label>
            <datepicker v-model="code.expiry" input-class="form-control" :required="true" placeholder="Expiry Date"></datepicker>
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: {}
    }
  },

  methods: {
    submit() {
      this.$axios.post('/api/v1/admin/discounts', this.code).then(() => {
        this.$toasted.show('Discount code has been added', { duration: 5000 })
        this.$router.push({ name: 'admin.discounts.index' })
      })
    }
  }
}
</script>